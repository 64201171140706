import React from "react"

export default function LokalerMovie({ text }) {
  return (
    <div className="relative bg-black">
      <div className="relative h-[50vh] lg:h-[70vh]">
        <video
          className="object-cover inset-0 w-full h-full "
          autoPlay
          playsInline
          muted
          loop
        >
          <source
            src="https://olleburl.in/slagthuset-wp/wp-content/uploads/2022/12/trimmed.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="absolute w-full left-0 bottom-0 h-full  flex flex-col items-center justify-center">
        <h3 className="text-3xl lg:text-7xl text-white">{text}</h3>
        {/* <div className="text xl lg:text-3xl">
            <p>Läs mer om </p>
          </div> */}
      </div>
    </div>
  )
}
